class WLabs {
  /**
   * Description
   * @function external: "String".foo
   * @author Bruno Aquino Filardi Filho
   * @version 1.0.0
   * @see @link{http://www.google.com}
   * @param{string} value - Valor
   * @return{boolean} Retorno
   */
  constructor(option) {
    var that = this;
    this.options = $.extend(true, {}, WLabs.DEFAULTS, typeof option === "object" && option);
  }
  /**
   * Description
   * @function external: "String".foo
   * @author Bruno Aquino Filardi Filho
   * @version 1.0.0
   * @see @link{http://www.google.com}
   * @param{string} value - Valor
   * @return{boolean} Retorno
   */
  updatePlan(container, plans) {
    var that = this;
    let planPeriod = $(".wlabs-pricing-period:checked", container).val();
    let plan = plans[planPeriod];
    plan.forEach(function(p) {
      let $plan = $('.wlabs-price[data-level="' + p.level + '"]', container);
      $(".wlabs-pricing-info", $plan).remove();
      if($plan.length) {
        let pp, monthlyValue;
        if(planPeriod == "yearly") {
          pp = that.options.lang.MONTH;
          monthlyValue = Math.round(p.value / 12);
          let message = WLabs.built(that.options.lang.BILLING, {
            price: that.options.lang.CURRENCY + p.value
          });
          $(".pricing-value", $plan).append("<div class=\"wlabs-pricing-info\">* " + message + "</div>");
        } else {
          pp = that.options.lang.MONTH;
          monthlyValue = Math.round(p.value * 100) / 100;
        }
        $(".price", $plan).html(monthlyValue + '<i class="wlabs-price-period">/' + pp + "</i>");
        $(".price", $plan).attr("data-before", that.options.lang.CURRENCY);
        $(plan).data("pid", p.id);
        let url = that.options.plan.link(p.id);
        $(".card-body a", $plan).attr("href", url);
      }
    });
  }
  /**
   * Description
   * @function external: "String".foo
   * @author Bruno Aquino Filardi Filho
   * @version 1.0.0
   * @see @link{http://www.google.com}
   * @param{string} value - Valor
   * @return{boolean} Retorno
   */
  init() {
    var that = this;
    let isWhite = $("body").hasClass("wlabs-white");
    let header = that.header(isWhite);
    let footer = that.footer(isWhite);
    let brands = that.listBrands();
    if(isWhite) {
      header.addClass("dark-link");
    }
    $("body").prepend(header);
    $("main").append(footer);
    $(document).ready(function() {
      let vars = WLabs.getUrlVars();
      
      console.log(vars);
      try{
        
        
      if(vars.wlt) {
        let title = atob(vars.wlt);
        
        console.log("title1", title);
        
        if(title) {
          title = decodeURIComponent(title);
          
          console.log("title2", title);
          
          $("#wlabs-title").html(title);
        }
      }
      if(vars.wlst) {
        let subtitle = atob(vars.wlst);
        if(subtitle) {
          $("#wlabs-subtitle").html(decodeURIComponent(subtitle));
        }
      }
      $("#wlabs-title").show();
      $("#wlabs-subtitle").show();
    }catch(e){
      $("#wlabs-title").show();
      $("#wlabs-subtitle").show();
    }
    
      if($(".wlabs-badges").length && that.options.badges) {
        that.options.badges.forEach(function(o) {
          let el = $(`<a title="${o.name}" href="${o.href}"> <img alt="${o.name}" border="0" src="${o.src}" /></a>`);
          $(".wlabs-badges").append(el);
        });
      }
      $(".wlabs-app-name").html(that.options.appName);
      $(".wlabs-app-web").html(that.options.appWeb);
      $(".wlabs-mailto").each(function() {
        $(this).html(that.options.contact).attr("href", "mailto:" + that.options.contact);
      });
      let mailsulfix = "@" + that.options.contact.split("@")[1];
      if(mailsulfix) {
        $(".wlabs-email-sulfix").html(mailsulfix);
      }
      $(".wlabs-mailto-legal").each(function() {
        $(this).html(that.options.lawyer.name).attr("href", "mailto:" + that.options.lawyer.email);
      });
      if(that.options.social.facebook) {
        $("#wlabs-social", footer).append(`<a href="${that.options.social.facebook}" target="_blank" class="btn btn-circle btn-sm btn-secondary me-3 op-4 wlabs-facebook" title="Facebook"><i class="fab fa-facebook"></i></a>`);
      }
      if(that.options.social.linkedin) {
        $("#wlabs-social", footer).append(`<a href="${that.options.social.linkedin}" target="_blank" class="btn btn-circle btn-sm btn-secondary me-3 op-4 wlabs-linkedin" title="Linkedin"><i class="fab fa-linkedin"></i></a>`);
      }
      if(that.options.social.instagram) {
        $("#wlabs-social", footer).append(`<a href="${that.options.social.instagram}" target="_blank" class="btn btn-circle btn-sm btn-secondary me-3 op-4 wlabs-instagram" title="Instagram"><i class="fab fa-instagram"></i></a>`);
      }
      if(that.options.social.youtube) {
        $("#wlabs-social", footer).append(`<a href="${that.options.social.youtube}" target="_blank" class="btn btn-circle btn-sm btn-secondary me-3 op-4 wlabs-youtube" title="Youtube"><i class="fab fa-youtube"></i></a>`);
      }
      if($("#contact-submit").length) {
        $("#contact_message").empty();
        $("#contact-submit").click(function(evt) {
          evt.preventDefault();
          let buttons = $(this);
          let form = $("[name=\"form-contact\"]");
          let message = form.next(".response-message");
          if(form.length) {
            if(!form.valid()) {
              return false;
            }
            let data = {
              submit: "submit",
            };
            $("input,select,textarea", form).each(function() {
              let name = $(this).attr("name");
              let value = $(this).val();
              data[name] = value;
            });
            buttons.attr("disabled", true);
            $.ajax({
              type: "POST",
              url: "resources/php/zendesk.php",
              data: data,
              dataType: "json",
            }).always(function(response, msg, srv) {
              buttons.attr("disabled", false);
              if(srv.status === 200 && response.status == 200) {
                form[0].reset();
                WLabs.alertMessage(that.options.lang.CONTACT_SUCCESS, {
                  type: "success",
                });
              } else {
                WLabs.alertMessage(response.message || that.options.lang.CONTACT_FAIL, {
                  type: "alert",
                });
              }
              $("#saas-form-submit-btn").prop("disabled", false).attr("disabled", false);
            });
          }
        });
      }
      if(that.options.plan) {
        let sessionPricing = $("#wlabs-session-pricing");
        if(sessionPricing.length) {
          that.options.plan.data(function(plans) {
            $(".wlabs-pricing-period").change(function() {
              that.updatePlan(sessionPricing, plans);
            });
            that.updatePlan(sessionPricing, plans);
          });
        }
      }
      if(that.options.zendesk) {
        DBWEB.initZenDeskChat({
          key: that.options?.zendesk?.clientId,
          tags: that.options?.zendesk?.tags,
          onload: false
        });
      }
    });
  }
  /**
   * Description
   * @function external: "String".foo
   * @author Bruno Aquino Filardi Filho
   * @version 1.0.0
   * @see @link{http://www.google.com}
   * @param{string} value - Valor
   * @return{boolean} Retorno
   */
  header(isWhite) {
    var that = this;
    const generateSidebarHeader = function() {
      const headerOptions = that.options.header;
      let $header = $("<div class=\"st-dropdown-content-group\"><h4 class=\"text-uppercase regular\">Menu</h4></div>");
      for(let keyHeader in headerOptions.options) {
        const innerOptions = headerOptions.options[keyHeader];
        if(!innerOptions?.skipContext && innerOptions?.href && innerOptions.name) {
          let $headerBtn = $(`<a class="regular text-primary"><i name="header-btn" class=""></i></a>`);
          $headerBtn.attr("href", innerOptions.href)
          $headerBtn.html(innerOptions.name)
          $("[name=\"header-btn\"]", $headerBtn).attr("class", innerOptions.icon)
          $header.append($headerBtn)
        }
      }
      return $header;
    }
    const generateSidebarFooter = function() {
      const footer = that.options.footer;
      let footerHtml = $("<div class=\"st-dropdown-content-group\">");
      for(let keyFooter in footer) {
        let footerOptions = footer[keyFooter];
        if(!footerOptions.skipContext && footer[keyFooter].title) {
          let fEl = $("<div class=\"col me-4\"><a></a></div>");
          $("a", fEl).html(footerOptions.title);
          if(footerOptions.options) {
            for(let attr in footerOptions.options) {
              let innerOptions = footerOptions.options[attr];
              if(!innerOptions?.skipContext && innerOptions?.name) {
                let footerLink = $("<a target=\"_blank\"></a>").html(innerOptions.name);
                if(innerOptions.href) {
                  footerLink.attr("href", innerOptions.href);
                }
                footerHtml.append(footerLink);
              }
            }
          }
        }
      }
      return footerHtml;
    }
    let component = $(`<nav class="st-nav navbar main-nav navigation fixed-top" id="main-nav"> <div class="container"> <ul class="st-nav-menu nav navbar-nav"> <li class="st-nav-section nav-item"> <a href="" class="navbar-brand"><img name="nav-logo" alt="logo" src="" class="logo logo-sticky d-inline-block d-md-none"><img name="nav-img" alt="logo-white" src="" class="logo d-none d-md-inline-block"> </a> </li> <li class="st-nav-section st-nav-primary nav-item"><li class="st-nav-section st-nav-mobile nav-item"><button class="st-root-link navbar-toggler" type="button"><span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span></button> <div class="st-popup"> <div class="st-popup-container"><a class="st-popup-close-button">Close</a> </div> </div> </li> </ul> </div> <div class="st-dropdown-root"> <div class="st-dropdown-bg"> <div class="st-alt-bg"></div> </div> <div class="st-dropdown-arrow"></div> <div class="st-dropdown-container"></div></div></nav>`);
    $(".navbar-brand", component).attr("href", that.options.header.options.home.href);
    if(that.options.logo) {
      $("[name=\"nav-logo\"]", component).attr("src", that.options.logo);
    }
    if(isWhite) {
      $("[name=\"nav-img\"]", component).attr("src", that.options.logo);
    } else {
      $("[name=\"nav-img\"]", component).attr("src", that.options.logoLight);
    }
    $(".st-popup-container", component).append(generateSidebarHeader());
    $(".st-popup-container", component).append(generateSidebarFooter());
    if(that.options.header.login) {
      let loginEl = $("<div class=\"st-dropdown-content-group bg-light b-t\"><a>Login&nbsp;<i class=\"fas fa-sign-in-alt\"></i></a></div>");
      $("a", loginEl).attr("href", that.options.header.login.url);
      $(".st-popup-container", component).append(loginEl);
    }
    if(that.options.header.login?.url) {
      let loginPanel = $(`<li class="st-nav-section st-nav-secondary nav-item">
					<a name="nav-login-btn" class="btn btn-rounded btn-outline me-3 px-3" target="_blank">
						<i class="fas fa-sign-in-alt d-none d-md-inline me-md-0 me-lg-2"></i> 
						<span class="d-md-none d-lg-inline">Login</span> 
					</a>
					<a name="nav-singup-btn" class="btn btn-rounded btn-solid px-3">
						<i class="fas fa-user-plus d-none d-md-inline me-md-0 me-lg-2"></i> 
					<span class="d-md-none d-lg-inline">Signup</span>
				</a>
			</li>`);
      $("[name=\"nav-login-btn\"", loginPanel).attr("href", that.options.header.login.url);
      $("[name=\"nav-singup-btn\"", loginPanel).attr("href", "index.html#wlabs-session-pricing");
      $(".st-nav-menu", component).append(loginPanel);
    }
    let i = 0;
    let left = $("<div class=\"col me-4\">");
    let right = $("<div class=\"col me-4\">");
    for(let attr in that.options.header.options) {
      let opt = that.options.header.options[attr];
      if(opt) {
        let $el = $("<a class=\"st-root-link nav-link\"></a>");
        $el.css("order", opt.order ? opt.order : Object.keys(that.options.header.options).length);
        if(opt.class) {
          $el.addClass(opt.class);
        }
        $el.attr("href", opt.href);
        $el.html(opt.name);
        let mel = '<a class="' + (opt.class || "") + '" href="' + opt.href + '">' + opt.name + "</a>";
        $(".st-nav-primary", component).append($el);
        if(i % 2 == 1) {
          right.append(mel);
        } else {
          left.append(mel);
        }
        i++;
      }
    }
    $(".st-dropdown-content-group .row", component).append(left).append(right);
    return component;
  }
  /**
   * Description
   * @function external: "String".foo
   * @author Bruno Aquino Filardi Filho
   * @version 1.0.0
   * @see @link{http://www.google.com}
   * @param{string} value - Valor
   * @return{boolean} Retorno
   */
  footer() {
    var that = this;
    let component = $(`<footer class="site-footer section b-t">
			<div class="container pb-3">
				<div class="row gap-y text-center text-md-start">
					<div class="col-md-4 me-auto">
						<a name="logo-btn" target="_blank"><img alt="WLabs" name="logo-img" src="" alt="" class="logo"></a>	 
						<p id="wlabs-footer-slogan"></p>
						<div class="wlabs-footer-secure"> 
              <img src="" alt="logo-pagarme" name="logo-pagarme">
							<img src="" alt="loge-bade" name="loge-bade"> 
							<img src="" alt="logo-seal" name="logo-seal"> 
						</div>
						<div id="wlabs-footer-payment" class="wlabs-footer-secure"></div>
					</div>
					<div id="wlabs-footer-company" class="col-md-2">
						<p class="h6 py-2 bold text-uppercase" name="footer-col-company"></p>
						<nav  class="nav flex-column"></nav>
					</div>
					<div id="wlabs-footer-util" class="col-md-2">
						<p class="h6 py-2 bold text-uppercase" name="footer-col-util"></p>
						<nav  class="nav flex-column"></nav>
					</div>
					<div id="wlabs-footer-legal"  class="col-md-2">
						<p class="h6 py-2 bold text-uppercase" name="footer-col-legal"></p>
						<nav  class="nav flex-column"></nav>
					</div>
				</div>
				<hr class="mt-5">
				<div class="row small align-items-center">
					<div class="col-md-4">
						<p class="mt-2 mb-md-0 text-secondary text-center text-md-start" id="wlabs-footer-copy"></p>
					</div>
					<div  class="col-md-8">
						<nav id="wlabs-social" class="nav justify-content-center justify-content-md-end">
						</nav>
					</div>
				</div>
			</div>
		</footer>`);
    $("[name=\"logo-pagarme\"", component).attr("src", that.options.footer.icons.pagarme);
    $("[name=\"loge-bade\"", component).attr("src", that.options.footer.icons.secureBadge);
    $("[name=\"logo-seal\"", component).attr("src", that.options.footer.icons.seal);
    $("[name=\"footer-col-company\"", component).html(that.options.footer.company.title);
    $("[name=\"footer-col-util\"", component).html(that.options.footer.util.title);
    $("[name=\"footer-col-legal\"", component).html(that.options.footer.legal.title);
    if(that.options.wlabsURL) $("[name=\"logo-btn\"", component).attr("href", that.options.wlabsURL);
    if(that.options.wlLogo) $("[name=\"logo-img\"", component).attr("src", that.options.wlLogo);
    if(that.options.footer) {
      $("#wlabs-footer-slogan", component).html(that.options.footer.slogan);
      $("#wlabs-footer-copy", component).html(that.options.footer.copy);
      if(that.options.footer.payment) {
        $("#wlabs-footer-payment", component).html(`<img src="${that.options.footer.icons.pay}" alt="logo-secure-pay">`);
      }
      if(that.options.footer.company) {
        $("#wlabs-footer-company h6", component).html(that.options.footer.company.title);
        if(that.options.footer.company.options) {
          WLabs.createrFooterElement($("#wlabs-footer-company nav", component), that.options.footer.company.options);
        }
      }
      if(that.options.footer.util) {
        $("#wlabs-footer-util h6", component).html(that.options.footer.util.title);
        if(that.options.footer.util.options) {
          WLabs.createrFooterElement($("#wlabs-footer-util nav", component), that.options.footer.util.options);
        }
      }
      if(that.options.footer.legal) {
        $("#wlabs-footer-legal h6", component).html(that.options.footer.legal.title);
        if(that.options.footer.legal.options) {
          WLabs.createrFooterElement($("#wlabs-footer-legal nav", component), that.options.footer.legal.options);
        }
      }
    }
    return component;
  }
  /**
   * Description
   * @function external: "String".foo
   * @author Esdras Henrique de Oliveira Pereira
   * @version 1.0.0
   * @see @link{http://www.google.com}
   * @param{string} value - Valor
   * @return{boolean} Retorno
   */
  listBrands() {
    function randomizeBrands(brandsToRandomize) {
      for(var i = brandsToRandomize.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = brandsToRandomize[i];
        brandsToRandomize[i] = brandsToRandomize[j];
        brandsToRandomize[j] = temp;
      }
      return brandsToRandomize;
    }
    var that = this;
    var brandContainer = document.getElementById("brand-container");
    if(brandContainer && that.options.brands) {
      var maxItems = brandContainer.getAttribute("data-n-items");
      var type = brandContainer.getAttribute("data-type");
      var brands = that.options.brands;
      var typho = brandContainer.getAttribute("data-filter-typho");
      var product = brandContainer.getAttribute("data-filter-product");
      if(brandContainer.getAttribute("data-order") === "random") brands = randomizeBrands(brands);
      if(typho) brands = brands.filter((brand) => brand.typhography === typho);
      if(product) brands = brands.filter((brand) => brand.products.some((pro) => pro === product));
      brands.forEach((brand, index) => {
        if(maxItems && index >= maxItems) return brandContainer;
        var brandDiv = document.createElement("div");
        brandDiv.className = type === "carrousel" ? "swiper-slide" : "col-md-4 col-xs-4 col-6 px-md-5 mt-4";
        brandDiv.innerHTML = WLabs.built(`
				<a href="{{url}}" target="_blank" title="{{name}} - {{url}}
          }" style="align-items: center; justify-content: center; display: flex;">
					<img src="{{logo}}" alt="{{name}}" class="img-responsive carrousel-img {{type}}">
				</a>
			`, {
          url: brand.url,
          name: brand.name,
          logo: brand.logo,
          type: type === "carrousel" ? "" : "mx-auto op-7"
        });
        brandContainer.appendChild(brandDiv);
      });
      return brandContainer;
    } else return undefined;
  }
}
/**
 * Description
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @see @link{http://www.google.com}
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
WLabs.createrFooterElement = function(container, config) {
  for(let attr in config) {
    let opt = config[attr];
    if(opt) {
      let el = '<a class="nav-item py-2 ' + (opt.class || "") + '" href="' + opt.href + '">' + opt.name + "</a>";
      $(container).append(el);
    }
  }
};
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
WLabs.alertMessage = function(message, option) {
  function _alert() {
    var pNotifyOptions = $.extend(true, {
      title: option.title,
      icon: false,
      text: message,
      type: "info",
      addclass: "app-alert",
    }, options);
    var notice = new PNotify(pNotifyOptions);
  }
  var DEFAULTS = {
    onClick: undefined,
  };
  var options = $.extend(true, {}, DEFAULTS, typeof option === "object" && option);
  if(typeof PNotify == "undefined") {
    WLabs.load("public/js/pnotify.custom.min.js", function() {
      WLabs.load("public/css/pnotify.css", function() {
        WLabs.load("public/css/pnotify.custom.min.css", function() {
          _alert();
        });
      });
    });
  } else {
    _alert();
  }
};
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
WLabs.built = function(string, obj) {
  return string.replace(/{{(.*?)}}/g, function(match, key) {
    return obj[key.trim()] || match;
  });
}
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
WLabs.load = function(l, callback) {
  _onLoad: {
    if(typeof l === "string") {
      var ext = l.split(".").pop().toLowerCase();
      _ext: {
        if(ext === "css") {
          $("head").append('<link rel="stylesheet" type="text/css" href="' + l + '">'); //  loadCSS(l);
          if(typeof callback !== "undefined") {
            callback();
          }
          break _ext; // jshint ignore:line
        }
        if(ext === "js") {
          $.getScript(l, function(data, textStatus, jqxhr) {
            if(jqxhr.status === 200) {
              if(typeof callback !== "undefined") {
                callback();
              }
            } else {
              throw "Invalid onLaunch file.";
            }
          });
          break _ext; // jshint ignore:line
        }
      }
      break _onLoad; // jshint ignore:line
    }
    if(typeof l === "function") {
      l(that);
      if(typeof callback !== "undefined") {
        callback();
      }
      break _onLoad; // jshint ignore:line
    }
  }
};
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 * @see @link{ http://api.exchangeratesapi.io/}
 */
WLabs.getCurrency = function(from, to, callback) {
  if(from == to) {
    callback(1);
  } else {
    var url = "https://economia.awesomeapi.com.br/json/last/" + from.toUpperCase() + "-" + to.toUpperCase();
    $.get(url).always(function(data, status, srv) {
      callback(data);
    });
  }
};
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
WLabs.obj2Query = function(seo) {
  if(!seo) {
    return [];
  }
  let seoq = [];
  for(let attr in seo) {
    seoq.push(attr + "=" + seo[attr]);
  }
  return seoq;
};
WLabs.PLAN = {
  /**
   * Description
   * @function external: "String".foo
   * @author Bruno Aquino Filardi Filho
   * @version 1.0.0
   * @see @link{http://www.google.com}
   * @param{string} value - Valor
   * @return{boolean} Retorno
   */
  DEFAULT: function(option) {
    let DEFAULTS = {
      url: "https://wbudget.app/register.html",
      params: [],
    };
    var options = $.extend(true, {}, DEFAULTS, typeof option === "object" && option);
    return function(pid) {
      let params = ["p=" + pid].concat(options.params);
      let search = (window.location.search || "").replace("?", "");
      if(search != "") {
        params.push(search);
      }
      if(typeof dbweb != "undefined") {
        let seo = dbweb.getSEOData();
        if(seo) {
          params = params.concat(WLabs.obj2Query(seo));
        }
      }
      params = [...new Set(params)];
      let url = options.url + "?" + params.join("&");
      return url;
    };
  },
};
WLabs.getUrlVars = function(separator = "?") {
  let url = window.location.href;
  if(url.indexOf(separator) == -1) {
    return null;
  }
  let vars = {},
    hash;
  var hashes = url.slice(url.indexOf(separator || "#") + 1).split("&");
  for(var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    vars[hash[0]] = hash[1];
  }
  return vars;
};
/**
 * Description
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @see @link{http://www.google.com}
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
WLabs.DEFAULTS = {
  zendesk: {
    clientId: "58757a78-af5b-4a52-873f-ae407248e761",
    tag: undefined
  },
  badges: [{
    name: "capterra",
    href: "https://www.capterra.com/reviews/286659/WBudget?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge",
    src: "https://assets.capterra.com/badge/ba0362f593bc432ccfcdec04e4cc32f6.svg?v=2251937&p=286659",
  }, {
    name: "getapp",
    href: "https://www.getapp.com/sales-software/a/wbudget/reviews/",
    src: "https://www.getapp.com/ext/reviews_widget/v1/dark/wbudget-application",
  }, {
    name: "softwareadvice",
    href: "https://www.softwareadvice.com/proposal-management/wbudget-profile/reviews/",
    src: "https://badges.softwareadvice.com/reviews/a2ba5fc5-faeb-4f46-944a-dd0b8c39d057",
  }],
  brands: BRANDS,
  wlLogo: "resources/img/wl.png",
  logo: "resources/img/wl.png",
  logoLight: "resources/img/wl-light.png",
  plan: undefined,
  lang: {
    CONTACT_SUCCESS: "Em breve entraremos em contato!<br>Agradecemos o seu contato!",
    CONTACT_FAIL: "Favor tentar mais tarde",
    MONTH: "mês",
    YEAR: "ano",
    CURRENCY: "R$",
    BILLING: "valor aproximado faturado anualmente em um <b>único pagamento de {{price}}</b>",
  },
  wlabsURL: "https://www.wlabs.com.br/",
  appName: "WBudget",
  appWeb: "www.wbudget.co",
  lawyer: {
    name: "Rodrigo Filardi",
    email: "contact@wlabs.co",
  },
  social: {
    facebook: "https://www.facebook.com/wlabs.com.br",
    linkedin: "https://www.linkedin.com/company/wlabs",
    instagram: "https://www.instagram.com/wlabs.pro",
    youtube: "https://www.youtube.com/@WLabsOfficial",
  },
  phone: [],
  contact: "contact@wlabs.co",
  header: {
    login: {
      url: "https://wbudget.app",
    },
    options: {
      home: {
        href: "index.html",
        name: "Home",
        order: 1,
      },
      system: {
        href: "index.html#wlabs-integration",
        name: "Integrações",
        order: 2
      },
      features: {
        href: "index.html#wlabs-session-pricing",
        name: "Preços",
        order: 3
      },
      faq: {
        href: "index.html#wlabs-session-faq",
        name: "FAQ's",
        order: 4
      },
      company: {
        href: "about.html",
        name: "Sobre",
        order: 5
      },
      contact: {
        href: "contact.html",
        name: "Contato",
        order: 6
      },
    },
  },
  footer: {
    icons: {
      pagarme: "public/img/pagarme.svg",
      secureBadge: "public/img/secure-badge.webp",
      seal: "public/img/seal.webp",
      pay: "public/img/pay.webp",
    },
    payment: true,
    slogan: "Soluções empresariais inteligentes que integram as diversas áreas do seu negócio.",
    copy: "© " + new Date().getFullYear() + " WLabs. All rights reserved",
    company: {
      title: "Empresa",
      options: {
        about: {
          href: "about.html",
          name: "Sobre",
        },
        partner: {
          class: "wlabs-saas-soon",
          href: null,
          name: "Parcerias <sup>em breve</sup>",
        },
        work: {
          class: "wlabs-saas-soon",
          href: null,
          name: "Trabalhe conosco <sup>em breve</sup>",
        },
        contact: {
          href: "contact.html",
          name: "Contato",
        },
        schedule: null,
      },
    },
    util: {
      title: "Links Úteis",
      options: {
        register: {
          name: "Cadastrar",
          href: "index.html#wlabs-session-pricing",
        },
        login: {
          href: null,
          name: "Login",
        },
        movies: {
          href: "movies.html",
          name: "Vídeos",
        },
        faq: {
          href: null,
          name: "Conhecimento",
        },
      },
    },
    legal: {
      title: "Legal",
      options: {
        secure: {
          href: "secure.html",
          name: "Segurança",
        },
        privacy: {
          href: "privacy.html",
          name: "Privacidade",
        },
        terms: {
          href: "terms.html",
          name: "Termos de uso",
        },
        legal: {
          href: "legal.html",
          name: "Validade Jurídica",
        },
      },
    },
  }
};