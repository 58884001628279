// @codekit-prepend "../brands.js";
// @codekit-prepend "../DBWEB.js";
// @codekit-prepend "../WLabs.js";
// @codekit-append "../dashcore/stripe-menu.js"

var dbweb = new DBWEB("wlabs", {
    LANG: {
        ACCEPT: "Aceitar",
        COOKIE_ACCEPT: `WLabs <b>utiliza cookies</b> e outras tecnologias similares <b>para melhorar sua experiência</b> e, ao continuar navegando, você concorda com essas condições. <a target="_blank" href="https://www.wbudget.co/privacy.html">Saiba mais</a>.`
    }
});
dbweb.initCookie();
let wlabs = new WLabs({
    zendesk: {
        tag: ["wlpt"]
    },
    appName: "Wlabs",
    appWeb: "www.wlabs.com.br",
    logo: "resources/img/wl.png",
    logoLight: "resources/img/wl-light.png",
    lawyer: {
        name: "Rodrigo Filardi",
        email: "contact@wlabs.com.br",
    },
    lang: {
        CONTACT_SUCCESS: "Entraremos em contato em breve!<br>Agradecemos por entrar em contato conosco!",
        CONTACT_FAIL: "Por favor, tente novamente mais tarde."
    },
    contact: "contato@wlabs.com.br",
    header: {
        login: null,
        options: {
            system: null,
            features: null,
            faq: null,
            company: null,
            home: {
                icon: "far fa-building me-2",
                href: "index.html",
                name: "Inicio",
                order: 1
            },
            wbudget: {
                icon: "fas fa-blog me-2",
                href: "wbudget",
                name: "WBudget",
                order: 2
            },
            wpages: {
                icon: "fas fa-blog me-2",
                href: "wpages",
                name: "WPages",
                order: 3
            },
            solar: {
                icon: "fas fa-blog me-2",
                href: "solar",
                name: "Módulo Solar&nbsp;<sup class=\"wl-tag-yellow\">NOVO</sup>",
                order: 4
            },
            contact: {
                icon: "far fa-envelope me-2",
                href: "contact.html",
                name: "Contato",
                order: 5
            },
            blog: {
                icon: "fas fa-blog me-2",
                href: "blog.html",
                name: "Blog",
                order: 6
            }
            // faq: {
            //     icon: "fas fa-wrench me-2",
            //     href: "index.html#wlabs-session-faq",
            //     name: "FAQ's"
            // },
            // company: {
            //     icon: "far fa-question-circle me-2",
            //     href: "about.html",
            //     name: "Sobre Nós"
            // },

        }
    },
    footer: {
        payment: true,
        slogan: "Soluções empresariais inteligentes que integram as diferentes áreas do seu negócio.",
        copy: "© " + new Date().getFullYear() + " WLabs. All rights reserved",
        company: {
            title: "Principal",
            options: {
                about: {
                    name: "Sobre nós"
                },
                partner: null,
                work: null,
                contact: {
                    name: "Contato"
                },
                schedule: {
                    name: "Agendar demonstração",
                    href: "schedule.html",
                },
                blog: {
                    href: "blog.html",
                    name: "Blog"
                },
            }
        },
        util: {
            title: "Links",
            options: {
                wbudget: {
                    name: "WBudget",
                    href: "https://www.wbudget.app?utm_source=wlabs"
                },
                solar: {
                    name: "WBudget - Solar&nbsp;<sup class=\"wl-tag-yellow\">NOVO</sup>",
                    href: "https://solar.wbudget.app?utm_source=wlabs"
                },
                wpages: {
                    name: "WPages",
                    href: "https://www.wpages.com.br?utm_source=wlabs"
                },
                register: null,
                login: null,
                movies: null,
                faq: null
            }
        },
        legal: {
            skipContext: true,
            title: "Legal",
            options: {
                secure: {
                    name: "Segurança"
                },
                privacy: {
                    name: "Privacidade"
                },
                terms: {
                    name: "Termos de uso"
                },
                legal: {
                    name: "Validade Jurídica"
                },
            }
        }
    }
});
wlabs.init();