const BRANDS = [{
    name: 'Asseng',
    logo: 'resources/img/brands/_asseng.webp',
    url: 'https://www.instagram.com/asseng.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Balancasmetra',
    logo: 'resources/img/brands/_balancasmetra.webp',
    url: 'http://balancasmetra.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Entec',
    logo: 'resources/img/brands/_entec.webp',
    url: 'http://grupoentec.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Ergoecia',
    logo: 'resources/img/brands/_ergoecia.webp',
    url: 'https://ergoecia.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Instalarcamara',
    logo: 'resources/img/brands/_instalarcamara.webp',
    url: 'http://www.instalarcamara.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'Libertyelev',
    logo: 'resources/img/brands/_libertyelev.webp',
    url: 'https://www.libertyelevadores.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Mlopes',
    logo: 'resources/img/brands/_mlopes.webp',
    url: 'https://mlopes.cnt.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Pdvprint',
    logo: 'resources/img/brands/_pdvprint.webp',
    url: 'https://www.pdvprint.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Rede',
    logo: 'resources/img/brands/_rede.webp',
    url: 'https://www.userede.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Resolvesst',
    logo: 'resources/img/brands/_resolvesst.webp',
    url: 'https://www.resolvesst.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Sistemaslife',
    logo: 'resources/img/brands/_sistemaslife.webp',
    url: 'http://lifesistema.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Teceng',
    logo: 'resources/img/brands/_teceng.webp',
    url: 'https://teceng.carrd.co/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Geradordedevs',
    logo: 'resources/img/brands/geradordedevs.webp',
    url: 'https://geradordedevs.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Interativachat',
    logo: 'resources/img/brands/interativachat.webp',
    url: 'https://interativachat.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Ndollinger',
    logo: 'resources/img/brands/ndollinger.webp',
    url: 'https://www.ndollinger.com/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'O3lab',
    logo: 'resources/img/brands/o3lab.webp',
    url: 'https://o3lab.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Rgdservice',
    logo: 'resources/img/brands/rgdservice.webp',
    url: 'https://rgd.eng.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Unitok',
    logo: 'resources/img/brands/unitok.webp',
    url: 'https://www.unitok.com/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Virtualticket',
    logo: 'resources/img/brands/virtualticket.webp',
    url: 'https://www.virtualticket.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: '3dlages',
    logo: 'resources/img/brands/3dlages.webp',
    url: 'https://3dlajes.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'Agenciacaprone',
    logo: 'resources/img/brands/agenciacaprone.webp',
    url: 'https://agenciacaprone.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'Biosafety',
    logo: 'resources/img/brands/biosafety.webp',
    url: 'http://biosafety.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'ciadaspiscinas',
    logo: 'resources/img/brands/ciadaspiscinas.webp',
    url: 'https://www.ciadaspiscinas.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'DGBtecnologia',
    logo: 'resources/img/brands/dgbtecnologia.webp',
    url: 'https://www.dgtecnologia.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'Elerib',
    logo: 'resources/img/brands/elerib.webp',
    url: 'http://eleribelevadores.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'Giromkt',
    logo: 'resources/img/brands/giromkt.webp',
    url: 'http://www.giromkt.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Gmgcolor',
    logo: 'resources/img/brands/gmgcolor.webp',
    url: 'https://gmgcolor.com/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Grupogarra',
    logo: 'resources/img/brands/grupogarra.webp',
    url: 'https://grupogarra.seg.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'Hermanmiller',
    logo: 'resources/img/brands/hermanmiller.webp',
    url: 'https://www.hermanmiller.com',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Ideiaclara',
    logo: 'resources/img/brands/ideiaclara.webp',
    url: 'https://ideiaclara.com/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'Imunova',
    logo: 'resources/img/brands/imunova.webp',
    url: 'https://imunova.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'Institutomulti',
    logo: 'resources/img/brands/institutomulti.webp',
    url: 'http://institutomulti.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'Integralsaude',
    logo: 'resources/img/brands/integralsaude.webp',
    url: 'https://www.integralsaudesorocaba.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'Interalpha',
    logo: 'resources/img/brands/interalpha.webp',
    url: 'http://www.interalpha.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'Jgpbrand',
    logo: 'resources/img/brands/jgpbrand.webp',
    url: 'https://jgpbrand.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'Projeted',
    logo: 'resources/img/brands/projeted.webp',
    url: 'https://projeted.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'RM',
    logo: 'resources/img/brands/rm.webp',
    url: 'https://rmsst.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}, {
    name: 'TI_plus',
    logo: 'resources/img/brands/ti_plus.webp',
    url: 'https://www.ti.plus/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'en-us'
}, {
    name: 'tlengenharia',
    logo: 'resources/img/brands/tlengenharia.webp',
    url: 'https://www.tlengenharia.com.br/',
    country: 'br',
    products: ['wb', 'wl', 'wp'],
    typhography: 'pt-br'
}];