class DBWEB {
	constructor(name, option) {
		var that = this;
		this.name = name;
		this.options = $.extend(true, {}, DBWEB.DEFAULTS, typeof option === 'object' && option);
		this.seo = null;
		console.log("v1.08");
	}
	/**
	 * Description.
	 * @function external: "String".foo
	 * @author Bruno Aquino Filardi Filho
	 * @version 1.0.0
	 * @param{string} value - Valor
	 * @return{boolean} Retorno
	 */
	printCookies() {
		var that = this;
		let name = that.name + "-seod";
		let seo = DBWEB.getCookie(name);
		console.log(seo);
	}
	/**
	 * Description.
	 * @function external: "String".foo
	 * @author Bruno Aquino Filardi Filho
	 * @version 1.0.0
	 * @param{string} value - Valor
	 * @return{boolean} Retorno
	 */
	getSEOData() {
		var that = this;
		let name = that.name + "-seod";
		let seo = DBWEB.getCookie(name);
		let params = DBWEB.getUrlVars();
		let data = $.extend(true, {}, seo, params);
		DBWEB.setCookie(name, data);
		if(data && data.p) {
			delete data.p;
		}
		return data;
	}
	/**
	 * Description.
	 * @function external: "String".foo
	 * @author Bruno Aquino Filardi Filho
	 * @version 1.0.0
	 * @param{string} value - Valor
	 * @return{boolean} Retorno
	 */
	initCookie() {
		var that = this;
		let name = that.name + "-accept";
		let accept = DBWEB.getCookie(name);
		if(accept && accept.accept) {
			that.seo = that.getSEOData();
		} else {
			let panel = $('<div class="db-cookie-panel"><div class="db-cookie-header"><div class="db-cookie-center"><img src="' + that.options.ico + '" alt="logo"></div>' + that.options.LANG.COOKIE_ACCEPT + '</div><div class="db-cookie-footer"><div class="db-cookie-btn">' + that.options.LANG.ACCEPT + '</div></div></div>');
			$('.db-cookie-btn', panel).click(function() {
				DBWEB.setCookie(name, {
					accept: true,
					date: new Date().toISOString()
				});
				panel.remove();
				that.seo = that.getSEOData();
			});
			$("body").append(panel);
		}
	}
}
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
DBWEB.setCookie = function(name, value, days = 7) {
	var expires = "";
	if(days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	let v = DBWEB.jsonB64Encode(value);
	document.cookie = name + "=" + (v || "") + expires + "; path=/";
}
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
DBWEB.getCookie = function(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while(c.charAt(0) == ' ') {
			c = c.substring(1, c.length);
			if(c.indexOf(nameEQ) == 0) {
				let value = c.substring(nameEQ.length, c.length);
				return DBWEB.jsonB64Decode(value);
			}
		}
	}
	return null;
}
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
DBWEB.eraseCookie = function(name) {
	document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
DBWEB.DEFAULTS = {
	ico: "https://www.wlabs.com.br/resources/img/wl.png",
	lang: "pt-br",
	LANG: {
		ACCEPT: "Aceitar",
		COOKIE_ACCEPT: "A WLabs <b>utiliza cookies</b> e outras tecnologias semelhantes <b>para melhorar a sua experiência</b> e, ao continuar navegando, você concorda com estas condições. <a target=\"_blank\" href=\"https://www.wbudget.app/privacy.html\">Saiba Mais</a>"
	}
};
/**
 * Verifica se o parâmetro é um objeto.
 * @author Bruno Aquino Filardi Filho
 * @function external:"window".isObject
 * @param{object} a - Valor que será verificado.
 * @version 1.0.0
 */
DBWEB.isObject = function(a) {
	return (!!a) && (a.constructor === Object);
};
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
DBWEB.jsonB64Encode = function(obj) {
	try {
		let content = (typeof obj == "string" && DBWEB.isJsonStr(obj)) || (DBWEB.isObject(obj) && obj);
		if(content) {
			return btoa(JSON.stringify(content));
		}
		return false;
	} catch (e) {
		return false;
	}
};
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
DBWEB.jsonB64Decode = function(b64) {
	try {
		let content = atob(b64);
		return DBWEB.isJsonStr(content);
	} catch (e) {
		return false;
	}
};
/**
 * Verifica se a String é um JSON.
 * @author Bruno Aquino Filardi Filho
 * @function external:"window".isJsonStr
 * @param{string} str - Valor que será verificado.
 * @version 1.0.0
 */
DBWEB.isJsonStr = function(str) {
	if(typeof str != "string") {
		return false;
	}
	try {
		return JSON.parse(str);
	} catch (e) {
		return false;
	}
};
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
DBWEB.loadAsset = function(type, url, callback) {
	var element;
	if(type === "js") {
		element = document.createElement("script");
		element.type = "text/javascript";
		element.src = url;
		element.onload = callback;
	} else if(type === "css") {
		element = document.createElement("link");
		element.rel = "stylesheet";
		element.type = "text/css";
		element.href = url;
		element.onload = callback;
	} else {
		console.error("Invalid asset type specified");
		return;
	}
	document.getElementsByTagName("head")[0].appendChild(element);
}
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
DBWEB.initZenDeskChat = function(option) {
	let DEFAULTS = {
		key: undefined,
		name: undefined,
		email: undefined,
		phone: undefined,
		description: undefined,
		tags: undefined,
		locale: "pt-br",
		label: "Help",
		onload: function(){
			let button = $("<div class=\"dbweb-zd-help\"><i class=\"far fa-user-headset\"></i>&nbsp;" + options.label + "</div>").click(function() {
				zE('messenger', 'open');
			});
			$("body").prepend(button);
		}
	};
	var options = $.extend(true, {}, DEFAULTS, typeof option === "object" && option);
	var scr = document.createElement("script");
	let url = "https://static.zdassets.com/ekr/snippet.js?key=" + options.key
	scr.setAttribute("src", url);
	scr.setAttribute("id", "ze-snippet");
	scr.type = "text/javascript";
	scr.async = true;
	scr.onload = function() {
		let visitor = {};
		if(options.name) {
			visitor.name = options.name;
		}
		if(options.email) {
			visitor.email = options.email;
		}
		if(options.phone) {
			visitor.phone = options.phone;
		}
		if(options.tags instanceof Array) {
			zE("messenger:set", "conversationTags", options.tags)
		}
		if(options.locale) {
			zE("messenger:set", 'locale', options.locale);
		}
		if(options.onload){
			options.onload();
		}
	};
	document.head.appendChild(scr);
}
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
DBWEB.getUrlVars = function(separator = "?") {
	let url = window.location.href;
	if(url.indexOf(separator) == -1) {
		return null;
	}
	var vars = {},
		hash;
	var hashes = url.slice(url.indexOf(separator || "#") + 1).split("&");
	for(var i = 0; i < hashes.length; i++) {
		hash = hashes[i].split("=");
		vars[hash[0]] = hash[1];
	}
	return vars;
}
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
DBWEB.getURLParams = function() {
	//let url = window.location.href.toString();
	let params = DBWEB.getUrlVars("?");
	return params;
}
/**
 * Description.
 * @function external: "String".foo
 * @author Bruno Aquino Filardi Filho
 * @version 1.0.0
 * @param{string} value - Valor
 * @return{boolean} Retorno
 */
DBWEB.isLocal = function() {
	return ["localhost", "127.0.0.1", "::1", "local"].includes(location.host);
}